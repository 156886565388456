import { combineEpics, ofType } from "redux-observable";
import { from, of } from "rxjs";
import { catchError, flatMap, map, mergeMap } from "rxjs/operators";
import {
  apiGetJots,

  apiGetProfile
} from "../../Api";
import * as actions from "./actions";
import {
  PROFILE_ERROR, PROFILE_REQUEST,






  USER_JOTS_ERROR, USER_JOTS_REQUEST
} from "./types";


// const perist$ = action$ =>
//   action$.pipe(
//     ofType(LOGOUT_REQUEST),
//     tap(response => console.log('THIS SHOLD WORK')),
//     mergeMap(action =>
//       of({
//         type: 'RESET',
//         payload: 'test',
//         error: true
//       })
//     )
//   );

const fetchProfileEpic = (action$) =>
  action$.pipe(
    ofType(PROFILE_REQUEST),
    mergeMap((action) =>
      from(apiGetProfile()).pipe(
        map((response) => actions.profileSuccess(response)),
        catchError((error) =>
          of({
            type: PROFILE_ERROR,
            payload: error,
            error: true,
          })
        )
      )
    )
  );

// const updateProfileEpic = action$ =>
//   action$.pipe(
//     ofType(PROFILE_UPDATE_REQUEST),
//     mergeMap(action => {
//       const { profile } = action;
//       const { twitter, instagram, ...profileObj } = profile;
//       profileObj.socials = [
//         { type: 'Twitter', url: twitter || 'Twitter' },
//         { type: 'Instagram', url: instagram || 'Instagram' }
//       ];
//       return from(apiSetProfile(profileObj)).pipe(
//         map(response => actions.profileSuccess(profileObj)),
//         catchError(error =>
//           of({
//             type: PROFILE_ERROR,
//             payload: error,
//             error: true
//           })
//         )
//       );
//     })
//   );

const searchUserJotsEpic = (action$, state$) =>
  action$.pipe(
    ofType(USER_JOTS_REQUEST),
    mergeMap((action) => {
      const offset = state$.value.profile.jots.allIds.length;
      return from(apiGetJots({ ...action.payload, offset })).pipe(
        flatMap((response) =>
          response.length
            ? of(actions.userJotsSuccess(response))
            : of({ type: "END OF JOTS" })
        ),
        catchError((error) =>
          of({
            type: USER_JOTS_ERROR,
            payload: error,
            error: true,
          })
        )
      );
    })
  );

// const pageUserJots = (action$) =>
//   action$.pipe(
//     ofType(USER_JOTS_SUCCESS),
//     mergeMap((action) => {
//       console.log("action", action)
//       return of({ type: USER_JOTS_REQUEST, payload: {...action.lastRequest}});
//     })
//   );

// const fetchHistoryEpic = action$ =>
//   action$.pipe(
//     ofType(HISTORY_REQUEST),
//     mergeMap(action =>
//       from(apiGetHistory()).pipe(
//         map(response => actions.historySuccess(response)),
//         catchError(error =>
//           of({
//             type: HISTORY_ERROR,
//             payload: error,
//             error: true
//           })
//         )
//       )
//     )
//   );

export default combineEpics(
  fetchProfileEpic,
  // pageUserJots,
  searchUserJotsEpic
  // fetchHistoryEpic,
  // perist$
);

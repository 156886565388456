// import errorTypes from '../systemError/types';
import { combineReducers } from 'redux';
import types from './types';

// const initialState = { isLoggedIn: false, loading: false, error: false, user: false, reset: false };

const isLoggedIn = (state = false, action) => {
  switch (action.type) {
    case types.LOGIN_SUCCESS:
      return true;
    case types.LOGIN_ERROR:
    case types.LOGIN_REQUEST:
    case types.LOGOUT_SUCCESS:
      return false;
    default:
      return state;
  }
};

const error = (state = false, action) => {
  switch (action.type) {
    case types.LOGIN_ERROR:
    case types.LOGOUT_ERROR:
    case types.REGISTER_ERROR:
    case types.VERIFY_ERROR:
    case types.PASSWORD_FORGOT_ERROR:
    case types.PASSWORD_RESET_ERROR:
      return action.err.message || action.err;
    case types.LOGIN_REQUEST:
    case types.LOGIN_SUCCESS:
    case types.LOGIN_ERROR_CLEAR:
      return false;
    default:
      return state;
  }
};

const loading = (state = false, action) => {
  switch (action.type) {
    case types.LOGIN_REQUEST:
    case types.LOGOUT_REQUEST:
    case types.REGISTER_REQUEST:
    case types.VERIFY_REQUEST:
    case types.PASSWORD_FORGOT_REQUEST:
    case types.PASSWORD_RESET_REQUEST:
      return true;
    case types.LOGIN_SUCCESS:
    case types.LOGOUT_SUCCESS:
    case types.REGISTER_SUCCESS:
    case types.VERIFY_SUCCESS:
    case types.PASSWORD_FORGOT_SUCCESS:
    case types.PASSWORD_RESET_SUCCESS:
    case types.LOGIN_ERROR:
    case types.LOGOUT_ERROR:
    case types.REGISTER_ERROR:
    case types.VERIFY_ERROR:
    case types.PASSWORD_FORGOT_ERROR:
    case types.PASSWORD_RESET_ERROR:
      return false;
    default:
      return state;
  }
};

const reset = (state = false, action) => {
  switch (action.type) {
    case types.PASSWORD_FORGOT_SUCCESS:
      return true;
    case types.PASSWORD_RESET_SUCCESS:
      return false;
    default:
      return state;
  }
};

const user = (state = false, action) => {
  switch (action.type) {
    case types.LOGIN_SUCCESS:
      return action.user;
    case types.LOGOUT_SUCCESS:
      return false;
    case types.REGISTER_SUCCESS:
      return { ...state, loading: false, email: action.email };
    default:
      return state;
  }
};

const email = (state = false, action) => {
  switch (action.type) {
    case types.REGISTER_SUCCESS:
      return action.email;
    case types.REGISTER_ERROR:
      return false;
    default:
      return state;
  }
};

export default combineReducers({
  user,
  email,
  isLoggedIn,
  reset,
  loading,
  error
});

// const authenticationReducer = (state = initialState, action) => {
//   switch (action.type) {

//     case types.LOGIN_FURTHER:
//       return { ...state, isLoggedIn: false, loading: false, user: action.user};

//       case types.LOGIN_SUCCESS:
//       return { ...state, isLoggedIn: true, loading: false, user: action.user};
//     case types.LOGIN_ERROR:
//       return { ...state, loading: false, error: action.err.message};

//     case types.LOGOUT_SUCCESS:
//       return { ...state, isLoggedIn: false, loading: false, user: false};
//     case types.LOGOUT_ERROR:
//       return { ...state, loading: false, error: action.err.message};

//     case types.REGISTER_SUCCESS:
//       return { ...state, loading: false, email: action.email};
//     case types.REGISTER_ERROR:
//       return { ...state, loading: false, error: action.err.message};

//     case types.VERIFY_SUCCESS:
//       return { ...state, loading: false};
//     case types.VERIFY_ERROR:
//       return { ...state, loading: false, error: action.err};

//     case types.PASSWORD_FORGOT_SUCCESS:
//       return {...state, loading: false, reset: true}
//     case types.PASSWORD_FORGOT_ERROR:
//       return {...state, loading: false, error: action.err, reset: false}

//     case types.PASSWORD_RESET_SUCCESS:
//       return {...state, loading: false, reset: false}
//     case types.PASSWORD_RESET_ERROR:
//       return {...state, loading: false, error: action.err, reset: false}
//     case errorTypes.CLEAR_ERROR:
//       return {...state, loading: false, error: false, reset: false}
//     default:
//       return state;
//   }
// }

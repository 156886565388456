import authenticationReducer from "./reducers";

// import * as authenticationSelectors from "./selectors";
import * as authenticationOperations from "./operations";
import * as authenticationTypes from "./types";
import * as authenticationActions from "./actions";


export {
    authenticationActions,
    authenticationOperations,
    authenticationTypes
};

export default authenticationReducer;

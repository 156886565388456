// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-app-js": () => import("./../../../src/pages/app.js" /* webpackChunkName: "component---src-pages-app-js" */),
  "component---src-pages-discover-js": () => import("./../../../src/pages/discover.js" /* webpackChunkName: "component---src-pages-discover-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-jotshare-js": () => import("./../../../src/pages/jotshare.js" /* webpackChunkName: "component---src-pages-jotshare-js" */),
  "component---src-pages-sso-js": () => import("./../../../src/pages/sso.js" /* webpackChunkName: "component---src-pages-sso-js" */),
  "component---src-pages-store-js": () => import("./../../../src/pages/store.js" /* webpackChunkName: "component---src-pages-store-js" */),
  "component---src-pages-warranty-js": () => import("./../../../src/pages/warranty.js" /* webpackChunkName: "component---src-pages-warranty-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */)
}


import {
  HISTORY_REQUEST,
  HISTORY_SUCCESS,
  HISTORY_ERROR,
  PROFILE_REQUEST,
  PROFILE_SUCCESS,
  PROFILE_ERROR,
  PROFILE_UPDATE_REQUEST,
  PROFILE_UPDATE_SUCCESS,
  PROFILE_UPDATE_ERROR,
  USER_JOTS_REQUEST,
  USER_JOTS_SUCCESS,
  USER_JOTS_ERROR,
} from './types';

export const profileRequest = () => ({
  type: PROFILE_REQUEST
});

export const profileSuccess = profile => ({
  type: PROFILE_SUCCESS,
  profile
});

export const profileError = () => ({
  type: PROFILE_ERROR
});


export const profileUpdateRequest = profile => ({
  type: PROFILE_UPDATE_REQUEST,
  profile
});

export const profileUpdateSuccess = () => ({
  type: PROFILE_UPDATE_SUCCESS
});

export const profileUpdateError = () => ({
  type: PROFILE_UPDATE_ERROR
});

export const userJotsRequest = ({ limit = 50, searchTerm = '', offset = 0 }) => ({
  type: USER_JOTS_REQUEST,
  payload: {
    limit,
    searchTerm,
    offset
  }
});

export const userJotsSuccess = (jots) => ({
  type: USER_JOTS_SUCCESS,
  payload: {
    jots
  }
});

export const userJotsError = err => ({
  type: USER_JOTS_ERROR,
  payload: {
    err
  }
});

export const userJotsRefresh = () => ({
  type: USER_JOTS_ERROR
});

export const historyRequest = () => ({
  type: HISTORY_REQUEST
});

export const historySuccess = response => ({
  type: HISTORY_SUCCESS,
  payload: {
    jots: response.Items,
    lastEvaluatedKey: response.LastEvaluatedKey
  }
});

export const historyError = () => ({
  type: HISTORY_ERROR
});

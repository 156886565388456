import React from "react"
import { Provider } from "react-redux"
import { PersistGate } from 'redux-persist/integration/react'

import configureStore from "./store"

let {store, persistor} = configureStore();

export default ({ element }) => {
    if (typeof window === "undefined") {
      return (
        <Provider store={store}>
          {element}
        </Provider>
      )
    }
    return (
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          {element}
        </PersistGate>
      </Provider>
    )
  }
//authActions
export const LOGIN_REQUEST = "app/authentication/LOGIN_REQUEST";
export const LOGIN_FURTHER = "app/authentication/LOGIN_FURTHER";
export const LOGIN_SUCCESS = "app/authentication/LOGIN_SUCCESS";
export const LOGIN_ERROR = "app/authentication/LOGIN_ERROR";
export const LOGIN_ERROR_CLEAR = "app/authentication/LOGIN_ERROR_CLEAR";

export const USER_INFO_REQUEST = "app/authentication/USER_INFO_REQUEST";
export const USER_INFO_SUCCESS = "app/authentication/USER_INFO_SUCCESS";
export const USER_INFO_ERROR = "app/authentication/USER_INFO_ERROR";

export const USER_DEVICES_REQUEST = "app/authentication/USER_DEVICES_REQUEST";
export const USER_DEVICES_SUCCESS = "app/authentication/USER_DEVICES_SUCCESS";
export const USER_DEVICES_ERROR = "app/authentication/USER_DEVICES_ERROR";

export const UPDATE_DEVICE_ALIAS = "app/authentication/UPDATE_DEVICE_ALIAS";

export const USER_DEVICES_SETUP = "app/authentication/USER_DEVICES_SETUP";
export const UPDATE_DEVICE_LIST = "app/authentication/UPDATE_DEVICE_LIST";

export const LOGOUT_REQUEST = "app/authentication/LOGOUT_REQUEST";
export const LOGOUT_SUCCESS = "app/authentication/LOGOUT_SUCCESS";
export const LOGOUT_ERROR = "app/authentication/LOGOUT_ERROR";

export const REGISTER_REQUEST = "app/authentication/REGISTER_REQUEST";
export const REGISTER_SUCCESS = "app/authentication/REGISTER_SUCCESS";
export const REGISTER_ERROR = "app/authentication/REGISTER_ERROR";

export const VERIFY_REQUEST = "app/authentication/VERIFY_REQUEST";
export const VERIFY_SUCCESS = "app/authentication/VERIFY_SUCCESS";
export const VERIFY_ERROR = "app/authentication/VERIFY_ERROR";

export const RESEND_VERIFY_REQUEST = "app/authentication/RESEND_VERIFY_REQUEST";
export const RESEND_VERIFY_SUCCESS = "app/authentication/RESEND_VERIFY_SUCCESS";
export const RESEND_VERIFY_ERROR = "app/authentication/RESEND_VERIFY_ERROR";

export const PASSWORD_CHANGE_REQUEST = "app/authentication/PASSWORD_CHANGE_REQUEST";
export const PASSWORD_CHANGE_SUCCESS = "app/authentication/PASSWORD_CHANGE_SUCCESS";
export const PASSWORD_CHANGE_ERROR = "app/authentication/PASSWORD_CHANGE_ERROR";

export const PASSWORD_RESET_REQUEST = "app/authentication/PASSWORD_RESET_REQUEST";
export const PASSWORD_RESET_SUCCESS = "app/authentication/PASSWORD_RESET_SUCCESS";
export const PASSWORD_RESET_ERROR = "app/authentication/PASSWORD_RESET_ERROR";

export const PASSWORD_FORCE_RESET_REQUEST = "app/authentication/PASSWORD_FORCE_RESET_REQUEST";
export const PASSWORD_FORCE_RESET_SUCCESS = "app/authentication/PASSWORD_FORCE_RESET_SUCCESS";
export const PASSWORD_FORCE_RESET_ERROR = "app/authentication/PASSWORD_FORCE_RESET_ERROR";

export const PASSWORD_FORGOT_REQUEST = "app/authentication/PASSWORD_FORGOT_REQUEST";
export const PASSWORD_FORGOT_SUCCESS = "app/authentication/PASSWORD_FORGOT_SUCCESS";
export const PASSWORD_FORGOT_ERROR = "app/authentication/PASSWORD_FORGOT_ERROR";

export default {
  LOGIN_REQUEST,
  LOGIN_FURTHER,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  LOGIN_ERROR_CLEAR,
  USER_INFO_REQUEST,
  USER_INFO_SUCCESS,
  USER_INFO_ERROR,
  USER_DEVICES_REQUEST,
  USER_DEVICES_SUCCESS,
  USER_DEVICES_ERROR,
  UPDATE_DEVICE_ALIAS,
  USER_DEVICES_SETUP,
  UPDATE_DEVICE_LIST,
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  LOGOUT_ERROR,
  REGISTER_REQUEST,
  REGISTER_SUCCESS,
  REGISTER_ERROR,
  VERIFY_REQUEST,
  VERIFY_SUCCESS,
  VERIFY_ERROR,
  RESEND_VERIFY_REQUEST,
  RESEND_VERIFY_SUCCESS,
  RESEND_VERIFY_ERROR,
  PASSWORD_CHANGE_REQUEST,
  PASSWORD_CHANGE_SUCCESS,
  PASSWORD_CHANGE_ERROR,
  PASSWORD_RESET_REQUEST,
  PASSWORD_RESET_SUCCESS,
  PASSWORD_RESET_ERROR,
  PASSWORD_FORCE_RESET_REQUEST,
  PASSWORD_FORCE_RESET_SUCCESS,
  PASSWORD_FORCE_RESET_ERROR,
  PASSWORD_FORGOT_REQUEST,
  PASSWORD_FORGOT_SUCCESS,
  PASSWORD_FORGOT_ERROR
};
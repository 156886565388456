import _ from 'lodash';
import { combineReducers } from 'redux';
import {
  HISTORY_REQUEST,
  HISTORY_SUCCESS, PROFILE_REQUEST,
  PROFILE_SUCCESS,










  USER_JOTS_REFRESH, USER_JOTS_REQUEST,
  USER_JOTS_SUCCESS
} from './types';

const objFromArray = array =>
  array.reduce((accumulator, current) => {
    accumulator[current.jotId] = current;
    return accumulator;
  }, {});

const idArray = array => array.map(jot => jot.jotId);

const updateCurrentPage = (state, action) => {
  const { payload } = action;
  const currentPage = payload.lastEvaluatedKey;
  return {
    ...state,
    ...currentPage
  };
};

const currentPage = (state = null, action) => {
  switch (action.type) {
    case USER_JOTS_SUCCESS:
      return updateCurrentPage(state, action);
    default:
      return state;
  }
};

const pagination = combineReducers({
  //pages,
  // refreshing: refreshing,
  currentPage
});

const addJotsById = (state, action) => {
  const { payload } = action;
  const jots = objFromArray(payload.jots || []);

  return {
    ...state,
    ...jots
  };
};

const userJotsById = (state = {}, action) => {
  switch (action.type) {
    case USER_JOTS_SUCCESS:
      return addJotsById(state, action);
    case USER_JOTS_REFRESH:
      return {};
    default:
      return state;
  }
};

const addUserProfile = (state, action) => {
  const { profile } = action;
  return {
    ...state,
    ...profile
  };
};

const userProfile = (state = {}, action) => {
  switch (action.type) {
    case PROFILE_SUCCESS:
      return addUserProfile(state, action);
    default:
      return state;
  }
};

const jotsloading = (state = false, action) => {
  switch (action.type) {
    case USER_JOTS_REQUEST:
      return true;
    case USER_JOTS_SUCCESS:
      return false;
    default:
      return state;
  }
};

const loading = (state = false, action) => {
  switch (action.type) {
    case USER_JOTS_REQUEST:
    case PROFILE_REQUEST:
      return true;
    case USER_JOTS_SUCCESS:
    case PROFILE_SUCCESS:
      return false;
    default:
      return state;
  }
};

const addJotIds = (state = [], action) => {
  const {
    payload: { jots = null }
  } = action;
  const jotIds = (jots && idArray(jots)) || [];
  // Just append the new Comment's ID to the list of all IDs
  return jotIds.length ? _.uniq(state.concat(jotIds)) : [];
};

const allUserJotIds = (state = [], action) => {
  switch (action.type) {
    case USER_JOTS_SUCCESS:
      return addJotIds(state, action);
    case USER_JOTS_REFRESH:
      return [];
    default:
      return state;
  }
};

const addHistoryById = (state, action) => {
  const { payload } = action;
  const jots = objFromArray(payload.jots);

  return {
    ...state,
    ...jots
  };
};

const userHistoryById = (state = {}, action) => {
  switch (action.type) {
    case HISTORY_SUCCESS:
      return addHistoryById(state, action);
    case HISTORY_REQUEST:
      return {};
    default:
      return state;
  }
};

const addHistoryIds = (state, action) => {
  const { payload } = action;
  const jotIds = idArray(payload.jots);
  // Just append the new Comment's ID to the list of all IDs
  return _.uniq(state.concat(jotIds));
};

const allUserHistoryIds = (state = [], action) => {
  switch (action.type) {
    case HISTORY_SUCCESS:
      return addHistoryIds(state, action);
    case HISTORY_REQUEST:
      return [];
    default:
      return state;
  }
};

const profileReducer = combineReducers({
  byId: userJotsById,
  allIds: allUserJotIds,
  loading: jotsloading
});

const historyReducer = combineReducers({
  byId: userHistoryById,
  allIds: allUserHistoryIds
});

export default combineReducers({
  jots: profileReducer,
  pagination,
  info: userProfile,
  history: historyReducer,
  loading
});

import types from './types';
import { combineReducers } from 'redux';


const checkout  = (state = [], action) => {
  switch (action.type) {
    case types.SET_CHECKOUT:
      return action.checkout
    default:
      return state;
  }
}


const shop  = (state = [], action) => {
  switch (action.type) {
    case types.SET_SHOP:
      return action.shop
    default:
      return state;
  }
}


const products  = (state = [], action) => {
  switch (action.type) {
    case types.SET_PRODUCTS:
      return action.products
    default:
      return state;
  }
}


const loading = (state = false, action) => {
  switch (action.type) {
    case types.GET_SHOP:
    case types.GET_PRODUCTS:
    case types.GET_CHECKOUT:
      return true;
      case types.SET_SHOP:
      case types.SET_PRODUCTS:
      case types.SET_CHECKOUT:
      return false;
    default:
      return state;
  }
};


const isOpen = (state = false, action) => {
  switch (action.type) {
    case types.OPEN_CART:
      return true;
    case types.CLOSE_CART:
      return false;
    default:
      return state;
  }
};

export default combineReducers({
  isOpen,
  checkout,
  shop,
  products,
  loading
});

// const authenticationReducer = (state = initialState, action) => {
//   switch (action.type) {

//     case types.LOGIN_FURTHER:
//       return { ...state, isLoggedIn: false, loading: false, user: action.user};

//       case types.LOGIN_SUCCESS:
//       return { ...state, isLoggedIn: true, loading: false, user: action.user};
//     case types.LOGIN_ERROR:
//       return { ...state, loading: false, error: action.err.message};

//     case types.LOGOUT_SUCCESS:
//       return { ...state, isLoggedIn: false, loading: false, user: false};
//     case types.LOGOUT_ERROR:
//       return { ...state, loading: false, error: action.err.message};

//     case types.REGISTER_SUCCESS:
//       return { ...state, loading: false, email: action.email};
//     case types.REGISTER_ERROR:
//       return { ...state, loading: false, error: action.err.message};

//     case types.VERIFY_SUCCESS:
//       return { ...state, loading: false};
//     case types.VERIFY_ERROR:
//       return { ...state, loading: false, error: action.err};

//     case types.PASSWORD_FORGOT_SUCCESS:
//       return {...state, loading: false, reset: true}
//     case types.PASSWORD_FORGOT_ERROR:
//       return {...state, loading: false, error: action.err, reset: false}

//     case types.PASSWORD_RESET_SUCCESS:
//       return {...state, loading: false, reset: false}
//     case types.PASSWORD_RESET_ERROR:
//       return {...state, loading: false, error: action.err, reset: false}
//     case errorTypes.CLEAR_ERROR:
//       return {...state, loading: false, error: false, reset: false}
//     default:
//       return state;
//   }
// }

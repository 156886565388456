import Amplify from 'aws-amplify';

export const identityPoolId = 'eu-west-1:13ddb7c0-b47f-4874-a225-f156a26e88ea';
export const region = 'eu-west-1';
export const userPoolId = 'eu-west-1_0jIKJjMtv';
export const clientId = '760tg2td5unc7hi3bm4v8gfdtn';
export const debugLevel = 'deubg';
export const mqttDebugLevel = true;
export const s3Bucket = 'platform-jots';
export const apiName = 'MyAPI';
export const apiEndpoint = 'https://api.joto.io';
export const iotEndpoint = 'a1k0tdyi37dcvg-ats.iot.eu-west-1.amazonaws.com';
export const stage = 'production';
export const imgixurl = 'https://joto.imgix.net';
export const shopURL = 'https://feed.joto.rocks/store';
export const supportURL = 'https://support.joto.rocks';
export const feedURL = 'https://www.feed.joto.rocks/stream';
export const aboutURL = 'https://joto.rocks';

export const AmplifyConfig = {
  Auth: {
    identityPoolId,
    region,
    userPoolId,
    userPoolWebClientId: clientId // OPTIONAL - Amazon Cognito Web Client ID
  },
  Storage: {
    bucket: s3Bucket, // REQUIRED -  Amazon S3 bucket
    region,
    identityPoolId
  },
  API: {
    endpoints: [
      {
        name: apiName,
        endpoint: apiEndpoint
      }
    ],
    region // OPTIONAL -  Amazon service region
  },
  PubSub: {
    aws_pubsub_region: region,
    aws_pubsub_endpoint: `wss://${iotEndpoint}/mqtt`
  }
};

Amplify.configure(AmplifyConfig);

// import storeProvider from '../../storeProvider';
import { MQTT_MESSAGE, MQTT_ERROR } from './types'
import MqttOverWSProvider from './refactor'
import _ from 'lodash'

// Amplify.Logger.LOG_LEVEL = 'DEBUG';
const PubSub = new MqttOverWSProvider()

const mqttManager = {
  isConnected: null,
  subscribers: [],
  subscriptions: [],
  topics: [],
}

const dispatchMQTTAction = ({ deviceId, action, subject, message }) => {
  // const store = storeProvider.getStore();
  // store.dispatch({
  //   type: action,
  //   deviceId,
  //   subject,
  //   message
  // });
}

// // const removeDuplicates = (myArr, prop) => {
// //   return myArr.filter((element, pos, arr) => {
// //     return arr.map(mapObj => mapObj[prop]).indexOf(element[prop]) === pos;
// //   });
// // };
export const publishTopic = async (topic, message) => {
  await PubSub.publish(topic, message)
}

export const subscribeTopics = ({ topics, deviceId, subject }) =>
  new Promise((resolve, reject) => {
    const events = {
      next: message =>
        dispatchMQTTAction({
          deviceId,
          action: MQTT_MESSAGE,
          subject,
          message,
        }),
      error: message =>
        dispatchMQTTAction({ deviceId, action: MQTT_ERROR, subject, message }),
      close: () => console.log(`Topic closed ${deviceId} - ${subject}`),
    }

    const subscription = PubSub.subscribe(topics).subscribe(events)
    mqttManager.subscriptions.push(subscription)

    if (Array.isArray(topics)) {
      topics.map(topic => {
        if (!_.find(mqttManager.subscribers, { topic: topic })) {
          mqttManager.subscribers.push({ topic, deviceId, subject })
        }
        return;
      })
    } else if (!_.find(mqttManager.subscribers, { topic: topics })) {
      mqttManager.subscribers.push({ topics, deviceId, subject })
    }
    return resolve()
  })

export const mqttConnect = () => {
  //initAwsIot();
}

import { ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, debounceTime, flatMap, mergeMap } from 'rxjs/operators';
import { apiSearchJots } from '../../Api';
import * as actions from './actions';
import types from './types';

export const searchEpic = action$ =>
  action$.pipe(
    ofType(types.SEARCH_REQUEST),
    mergeMap(action => {
      return from(apiSearchJots(action.payload)).pipe(
        flatMap(response => {
          console.log(response);
          return of(actions.searchSuccess(response));
        }),
        catchError(error =>
          of({
            type: types.SEARCH_ERROR,
            payload: error,
            error: true
          })
        )
      );
    }),
    debounceTime(1000)
  );

export default searchEpic;

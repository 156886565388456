import { createMemoryHistory } from "history";
import { routerMiddleware } from "react-router-redux";
import { applyMiddleware, combineReducers, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { logger } from "redux-logger";
import { combineEpics, createEpicMiddleware } from "redux-observable";
// import { connectRouter } from "connected-react-router";
//PERSIST
import { persistStore, persistReducer } from "redux-persist";
// import storage from "redux-persist/es/storage"; // default: localStorage if web, AsyncStorage if react-native
import reduxReset from 'redux-reset';
// import devicesEpic from './State/devices/epics';
import thunk from "redux-thunk";
// import { reducer as reduxFormReducer } from 'redux-form';
import * as allReducers from "./State";
import createEpic from "./State/create/epics";
import profileEpic from "./State/profile/epics";
import searchEpic from "./State/search/epics";



import createWebStorage from "redux-persist/lib/storage/createWebStorage";

const createNoopStorage = () => {
  return {
    getItem(_key) {
      return Promise.resolve(null);
    },
    setItem(_key, value) {
      return Promise.resolve(value);
    },
    removeItem(_key) {
      return Promise.resolve();
    },
  };
};

const storage = typeof window !== "undefined" ? createWebStorage("local") : createNoopStorage();


const rootEpic = combineEpics(profileEpic, searchEpic, createEpic);

const persistConfig = {
  key: "root",
  storage,
  // whitelist: ["authentication, profile, cart"]
};


// combineReducer applied on persisted(counterReducer) and NavigationReducer
const rootReducers = history =>
  combineReducers({
    ...allReducers,
    // router: connectRouter(history)
  });
  

const epicMiddleware = createEpicMiddleware();
let middleware = [thunk, epicMiddleware];
if (process.env.NODE_ENV !== 'production') {
  middleware = [...middleware, logger];
}

export const history = createMemoryHistory();

export default function configureStore(preloadedState={}) {
  const persistedReducer = persistReducer(persistConfig, rootReducers(history));
  const store = createStore(
    persistedReducer, // root reducer with router state
    preloadedState,
    composeWithDevTools(
      applyMiddleware(
        routerMiddleware(history), // for dispatching history actions
        ...middleware
      ),
      reduxReset()
    )
  );

  epicMiddleware.run(rootEpic);
  let persistor = persistStore(store);
  return {store, persistor};
}
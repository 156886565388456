// import * as actions from './actions';
// // import * as errorActions from '../systemError/actions';

// import Amplify, { PubSub, Auth } from 'aws-amplify';
// import { AmplifyConfig } from '../../config/index';

// Amplify.configure(AmplifyConfig);
import Client from "shopify-buy";
import * as actions from "./actions";
// import ReactPixel from "react-facebook-pixel";

export const client = Client.buildClient({
  storefrontAccessToken: "b10cb4ecc0caf74aab5286511e619ecb",
  domain: "jotoshop.myshopify.com",
});

const { checkout, product, shop } = client;

export const fetchProducts = () => (dispatch) => {
  dispatch(actions.getProducts());
  product.fetchAll().then((res) => {
    dispatch(actions.setProducts(res));
  }).catch((err) => {
    console.log("ERROR", err);
  });
};

export const fetchShop = () => (dispatch) => {
  dispatch(actions.getShop());
  shop.fetchInfo().then((res) => {
    dispatch(actions.setShop(res));
  });
};


const createCheckout = (dispatch) => {
  checkout.create().then((res) => {
    dispatch(actions.setCheckout(res));
  });
}
// TODO: check if checkout exists already;

export const getInitialCheckout = () => (dispatch, getState) => {
  dispatch(actions.getCheckout());
  let currentCheckout = getState().cart.checkout;
  
  if (!currentCheckout.id || currentCheckout.completed) {
    createCheckout(dispatch);
  } else {
    console.log("checking for existing checkout");
    client.checkout
      .fetch(currentCheckout.id)
      .then(response => {
        if(response === null){
          console.log("clearing old checkout");
          createCheckout(dispatch);
        }
      })
      .catch((err) => {
        console.log("clearing old checkout");
        createCheckout(dispatch);
      });
  }
};

export const updateQuantityInCart = (lineItemsToUpdate) => (dispatch) => {};

export const addVariantToCart = (variantId, quantity, trackingObject) => (
  dispatch,
  getState
) => {
  dispatch(actions.openCart());
  const lineItemsToAdd = [{ variantId, quantity: parseInt(quantity, 10) }];
  const checkout = getState().cart.checkout;
  return client.checkout
    .addLineItems(checkout.id, lineItemsToAdd)
    .then((res) => {
      // ReactPixel.track("AddToCart", trackingObject);
      //updateCartTotal();
      dispatch(actions.setCheckout(res));
    });
};

// export const updateQuantityInCart =  (lineItemId, quantity) =>  dispatch =>  {
//   return checkout
//     .removeLineItems(checkout.id, [lineItemId])
//     .then(res => {
//       updateCartTotal();
//     });
// };

// TODO: needs updating
export const removeLineItemInCart = (lineItem) => (dispatch, getState) => {
  let { id } = lineItem;
  const checkout = getState().cart.checkout;
  return client.checkout
    .removeLineItems(checkout.id, [id])
    .then((res) => dispatch(actions.setCheckout(res)));
};

// // TODO: not sure if this works
// export const updateCartTotal = () =>  dispatch =>  {
//   let total = checkout.lineItems.reduce(function(prev, cur) {
//     return prev + cur.quantity;
//   }, 0);
//   //dispatch(actions.updateTotal({total}))
// }

export const decrementQuantity = (lineItem) => (dispatch, getState) => {
  const checkout = getState().cart.checkout;
  return client.checkout
    .updateLineItems(checkout.id, [
      { id: lineItem.id, quantity: lineItem.quantity - 1 },
    ])
    .then((res) => {
      dispatch(actions.setCheckout(res));
    });
};

export const incrementQuantity = (lineItem) => (dispatch, getState) => {
  const checkout = getState().cart.checkout;
  return client.checkout
    .updateLineItems(checkout.id, [
      { id: lineItem.id, quantity: lineItem.quantity + 1 },
    ])
    .then((res) => {
      dispatch(actions.setCheckout(res));
    });
};

// export const openCart = () =>  dispatch =>  {

//   //dispatch(actions.updateTotal({total}))
// }

// withHandlers({

//   fetchProducts: ({ client, setProducts, setProductLoading }) => () => {
//     product.fetchAll().then(res => {
//       setProducts(res);
//       setProductLoading(false);
//     });
//   },
//   fetchShop: ({ client, setShop, setProductLoading }) => () => {
//     shop.fetchInfo().then(res => {
//       setShop(res);
//       setProductLoading(false);
//     });
//   },
//   updateQuantityInCart: ({
//     checkout,
//     lineItemsToUpdate,
//     setCheckout,
//     setCartLoading,
//     updateTotal
//   }) => () => {
//     return checkout
//       .updateLineItems(checkout.id, lineItemsToUpdate)
//       .then(res => {
//         setCheckout(res);
//         setCartLoading(false);
//         updateTotal();
//       });
//   },
//   addVariantToCart: ({
//     client,
//     checkout,
//     setIsCartOpen,
//     setCartLoading,
//     stopLoading,
//     setCheckout,
//     updateTotal,
//     ...rest
//   }) => (variantId, quantity) => {
//     setIsCartOpen(true);
//     setCartLoading(true);
//     const lineItemsToAdd = [{ variantId, quantity: parseInt(quantity, 10) }];
//     const checkoutId = checkout.id;
//     return checkout
//       .addLineItems(checkoutId, lineItemsToAdd)
//       .then(res => {
//         ReactPixel.track("AddToCart", lineItemsToAdd);
//         setCheckout(res);
//         console.log(res);
//         setCartLoading(false);
//         updateTotal();
//       });
//   },
//   updateQuantityInCart: ({
//     client,
//     checkout,
//     setCheckout,
//     setCartLoading,
//     updateTotal
//   }) => (lineItemId, quantity) => {
//     return checkout
//       .removeLineItems(checkout.id, [lineItemId])
//       .then(res => {
//         setCheckout(res);
//         setCartLoading(false);
//         updateTotal();
//       });
//   },
//   removeLineItemInCart: ({
//     client,
//     checkout,
//     setCheckout,
//     setCartLoading,
//     updateTotal
//   }) => lineItemId => {
//     return checkout
//       .removeLineItems(checkout.id, [lineItemId])
//       .then(res => {
//         setCheckout(res);
//         setCartLoading(false);
//         updateTotal();
//       });
//   },
//   handleCartClose: ({ setIsCartOpen }) => () => {
//     setIsCartOpen(false);
//   },
//   openCart: ({ setIsCartOpen }) => () => {
//     setIsCartOpen(true);
//   }
